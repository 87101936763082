import { FC } from 'react';
import { useFormik } from 'formik';
import { Grid, Box, Theme, } from '@mui/material';
import { Button } from 'modules/theme/components/button/Button';
import { Recaptcha } from 'components/recaptcha/components/Recaptcha';
import { IFormData } from 'components/page/leadgeneration/interfaces/IFormData';
import formValidationSchema from 'components/page/leadgeneration/constats/formValidationSchema';
import { SelectType } from 'components/page/leadgeneration/components/initial/form/selectType/SelectType';
import { Checkbox } from 'components/page/leadgeneration/components/initial/form/fields/checkbox/Checkbox';
import { TextInput } from 'components/page/leadgeneration/components/initial/form/fields/textInput/TextInput';
import { SelectDistrict } from 'components/page/leadgeneration/components/initial/form/selectDistrict/SelectDistrict';
import { principlesOfProtection } from 'modules/common/links/principlesOfProtection/principlesOfProtection';
import { googlePersonalDataToRealEstateConditions } from 'modules/common/links/googlePersonalDataToRealEstateConditions/googlePersonalDataToRealEstateConditions';

interface IForm {
    isSending?: boolean;
    onSubmit: (values: IFormData) => void;
    onFormInputTouch: (inputName: string, value: boolean) => void;
}

export const Form: FC<IForm> = ({ onSubmit, onFormInputTouch }) => {

    const formik = useFormik<IFormData>({
        initialValues: {
            name: '',
            type: '',
            email: '',
            district: '',
            phoneNumber: '',
            usableArea: undefined,
            agreeToGDPR: false,
            agreeToTerms: false,
            recaptchaToken: '',
            location: { latitude: 0, longitude: 0 }
        },
        validationSchema: formValidationSchema,
        onSubmit: onSubmit,
        validateOnChange: true,
    });

    const { touched, errors } = formik;
    const nameErrorMessage = touched.name && errors.name ? errors.name : undefined;
    const typeErrorMessage = touched.type && errors.type ? errors.type : undefined;
    const emailErrorMessage = touched.email && errors.email ? errors.email : undefined;
    const districtErrorMessage = touched.district && errors.district ? errors.district : undefined;
    const usableAreaErrorMessage = touched.usableArea && errors.usableArea ? errors.usableArea : undefined;
    const phoneNumErrorMessage = touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : undefined;
    const agreeToTermsErrorMessage = touched.agreeToTerms && errors.agreeToTerms ? errors.agreeToTerms : undefined;
    const agreeToGDPRErrorMessage = touched.agreeToGDPR && errors.agreeToGDPR ? errors.agreeToGDPR : undefined;
    const repatchaErrorMessage = touched.recaptchaToken && errors.recaptchaToken ? errors.recaptchaToken : undefined;

    return <Box
        p={4}
        margin='auto'
        maxWidth={635}
        borderRadius={1}
        component='form'
        border='1px solid'
        onSubmit={formik.handleSubmit}
        borderColor={(theme: Theme) => theme.palette.labelTertiary.main}
        bgcolor={(theme: Theme) => theme.palette.backgroundPrimary.main}
        data-test-id='+form'
    >
        <Grid container rowSpacing={{ xs: 2, md: 4 }} columnSpacing={2} mb={2}>
            <Grid item xs={12} data-test-id='name'>
                <TextInput
                    type='text'
                    label='Meno a priezvisko'
                    value={formik.values.name}
                    errorMsg={nameErrorMessage}
                    onChange={(value) => {
                        onFormInputTouch('name', !!value);
                        formik.setFieldValue('name', value);
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6} data-test-id='email'>
                <TextInput
                    type='text'
                    label='Email'
                    value={formik.values.email}
                    errorMsg={emailErrorMessage}
                    onChange={(value) => {
                        onFormInputTouch('email', !!value);
                        formik.setFieldValue('email', value);
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6} data-test-id='phoneNumber'>
                <TextInput
                    type='text'
                    label='Telefónne číslo'
                    value={formik.values.phoneNumber}
                    errorMsg={phoneNumErrorMessage}
                    onChange={(value) => {
                        onFormInputTouch('phoneNumber', !!value);
                        formik.setFieldValue('phoneNumber', value);
                    }}
                />
            </Grid>

            <Grid item xs={12} data-test-id='type'>
                <SelectType
                    errMsg={typeErrorMessage}
                    value={formik.values.type}
                    onChange={value => {
                        onFormInputTouch('type', !!value);
                        formik.setFieldValue('type', value);
                    }}
                />
            </Grid>

            <Grid item xs={12} data-test-id='district'>
                <SelectDistrict
                    errMsg={districtErrorMessage}
                    value={formik.values.district}
                    onChange={value => {
                        onFormInputTouch('district', !!value);
                        formik.setFieldValue('district', value);
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6} data-test-id='usableArea'>
                <TextInput
                    type='text'
                    label='Užitková plocha'
                    value={formik.values.usableArea > '0' ? formik.values.usableArea : ''}
                    errorMsg={usableAreaErrorMessage}
                    onChange={(value) => {
                        onFormInputTouch('usableArea', !!value);
                        if (/^\d*$/.test(value) && value.length <= 10) {
                            formik.setFieldValue('usableArea', value);
                        }
                    }}
                />

            </Grid>
        </Grid>

        <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12} data-test-id='agreeToTerms'>
                <Checkbox
                    isChecked={formik.values.agreeToTerms}
                    errMsg={agreeToTermsErrorMessage}
                    onChange={isChecked => {
                        onFormInputTouch('agreeToTerms', isChecked);
                        formik.setFieldValue('agreeToTerms', isChecked);
                    }}
                    linkText='Súhlasím s poskytnutím osobných údajov za účelom sprostredkovania možnosti využiť služby realitných kancelárií'
                    linkHref={googlePersonalDataToRealEstateConditions}
                />
            </Grid>

            <Grid item xs={12} data-test-id='agreeToGDPR'>
                <Checkbox
                    isChecked={formik.values.agreeToGDPR}
                    errMsg={agreeToGDPRErrorMessage}
                    linkText='Súhlasím so spracovaním osobných údajov v zmysle GDPR'
                    onChange={isChecked => {
                        onFormInputTouch('agreeToGDPR', isChecked);
                        formik.setFieldValue('agreeToGDPR', isChecked);
                    }}
                    linkHref={principlesOfProtection}
                />
            </Grid>

            <Grid item xs={12} data-test-id='recaptcha'>
                <Recaptcha
                    errorMsg={repatchaErrorMessage}
                    onChange={(token) => formik.setFieldValue('recaptchaToken', token)}
                />
            </Grid>

            <Grid item xs={12} md={3} data-test-id='submit'>
                <Button text='Odoslať' fullWidth disabled={formik.isSubmitting} type='submit'/>
            </Grid>

        </Grid>
    </Box>;
};
