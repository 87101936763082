import { FC } from 'react';
import Link from 'components/link/Link';
import { Box, Theme } from '@mui/material';
import Image from 'components/image/Image';
import { StaticImageData } from 'next/image';

import { Text } from 'modules/theme/components/text/Text';


interface IItem {
    title: string;
    articleHref: string;
    articleImage: StaticImageData;
}

export const Item: FC<IItem> = ({ title, articleImage, articleHref }) => {
    return <Link noUnderline openInNewTab href={articleHref}>
        <Box
            borderRadius={3}
            overflow='hidden'
            border='1px solid'
            borderColor={(theme: Theme) => theme.palette.labelTertiary.main}
        >

            <Image src={articleImage} alt={title} placeholder='blur'/>

            <Box px={2} py={2}>
                <Text variant='h5' bold>
                    {title}
                </Text>
            </Box>
        </Box>
    </Link>;
};
