import { FC } from 'react';
import { Box, Theme } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import Image from 'components/image/Image';
import HowItWorksBg from 'components/page/leadgeneration/components/initial/head/assets/HowItWorksBg.png';

export const HowItWorks: FC = () => {
    return (
        <Box
            p={{ xs: 2, sm: 4 }}
            marginX='auto'
            mb={3}
            maxWidth={635}
            borderRadius={1}
            border='1px solid'
            borderColor={(theme: Theme) => theme.palette.labelTertiary.main}
            bgcolor={(theme: Theme) => theme.palette.backgroundPrimary.main}
            overflow='hidden'
        >
            <Box
                position='relative'
                maxWidth={315}
                marginX='auto'
            >
                <Box zIndex={10}>
                    <Box maxWidth={200}>
                        <Text variant='h4' bold mb={1}>
                            Ako to funguje?
                        </Text>
                        <Text variant='body3' mb={1}>
                            Pošlite nám základné údaje cez formulár. My vám
                            nájdeme makléra na kľúč, ktorý prevezme váš predaj
                            do svojich rúk.
                        </Text>
                    </Box>
                    <Text variant='body3' bold>
                        Služba je nezáväzná a zadarmo.
                    </Text>
                </Box>
                <Box
                    position='absolute'
                    width={130}
                    bottom={-40}
                    right={{ xs: -40, sm: -15 }}
                >
                    <Image alt='background-image-makler' src={HowItWorksBg} width={130} height={172}/>
                </Box>
            </Box>
        </Box>
    );
};
