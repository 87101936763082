import { FC } from 'react';
import { Grid } from '@mui/material';
import Icon1 from 'components/page/leadgeneration/img/svg/hands.svg';
import Icon3 from 'components/page/leadgeneration/img/svg/graph.svg';
import Icon2 from 'components/page/leadgeneration/img/svg/advantage-house.svg';
import { Item } from 'components/page/leadgeneration/components/success/advantages/list/Item';

export const AdvantagesList: FC = () => {
    const advantages = [
        {
            title: 'Spolupracujeme s väčšinou realitných kancelárií na Slovensku.',
            icon: Icon1,
        },
        {
            title: 'Máme vlastný nástroj na oceňovanie nehnuteľností.',
            icon: Icon2,
        },
        {
            title: 'Neustále vyvíjame nové nástroje, ktoré zjednodušujú kúpu i predaj.',
            icon: Icon3,
        }
    ];

    return <Grid container justifyContent='center' spacing={{ xs: 2, md: 3 }}>
        {advantages.map((advantage, index: number) =>
            <Grid item key={index} xs={8} sm={4}>
                <Item title={advantage.title} icon={advantage.icon}/>
            </Grid>
        )}
    </Grid>;
};
