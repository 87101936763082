import { ELeadGenActionNames } from 'components/page/leadgeneration/enums/ELeadGenActionNames';

export const gaActionNamesMapper: Record<string, ELeadGenActionNames> = {
    'name': ELeadGenActionNames.Name,
    'email': ELeadGenActionNames.Email,
    'phoneNumber': ELeadGenActionNames.PhoneNumber,
    'type': ELeadGenActionNames.Type,
    'district': ELeadGenActionNames.District,
    'usableArea': ELeadGenActionNames.UsableArea,
    'agreeToTerms': ELeadGenActionNames.Terms,
    'agreeToGDPR': ELeadGenActionNames.GDPR
};