import { FC } from 'react';
import { Button } from 'modules/theme/components/button/Button';
import { Theme, useMediaQuery } from '@mui/material';
import { AppConfigService } from 'modules/appConfig/AppConfigService';

export const ActionButton: FC = () => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return <Button
        fullWidth={isMobile}
        text='Prejsť do magazínu'
        href={`${AppConfigService.getNehnutelnostiUrl()}/magazin-o-byvani/`}
    />;
};
