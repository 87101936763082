import { FC } from 'react';
import { Box, Container } from '@mui/material';
import { Headline } from 'components/page/leadgeneration/components/initial/head/text/Headline';
import { Description } from 'components/page/leadgeneration/components/initial/head/text/Description';
import { AbsoluteIcons } from 'components/page/leadgeneration/components/initial/head/absoluteIcons/AbsoluteIcons';

export const Head: FC = () => {
    return <Container maxWidth='lg' sx={{ position: 'relative' }}>

        <Box maxWidth={520} mb={5} marginX='auto'>
            <Box mb={3}>
                <Headline/>
            </Box>

            <Box>
                <Description/>
            </Box>
        </Box>

        <AbsoluteIcons/>

    </Container>;
};
