import { FC } from 'react';
import { Box } from '@mui/material';
import { Headline } from 'components/page/leadgeneration/components/success/advantages/text/Headline';
import { AdvantagesList } from 'components/page/leadgeneration/components/success/advantages/list/AdvantagesList';


export const Advantages: FC = () => {

    return <>
        <Box mb={5}>
            <Headline/>
        </Box>

        <Box>
            <AdvantagesList/>
        </Box>
    </>;
};
