import { FC } from 'react';
import { Container, Box, Theme } from '@mui/material';
import { useForm } from 'components/page/leadgeneration/hooks/useForm';
import { Form } from 'components/page/leadgeneration/components/initial/form/Form';
import { Head as InitialPagaHead } from 'components/page/leadgeneration/components/initial/head/Head';
import { HowItWorks } from 'components/page/leadgeneration/components/initial/head/text/HowItWorks';

interface IInitial {
    isFinished: boolean;
    onFinish: () => void;
    onFormInputTouch: (inputName: string, value: boolean) => void;
}

export const Initial: FC<IInitial> = ({ isFinished, onFinish, onFormInputTouch }) => {
    const { onSubmit } = useForm(onFinish);

    return <>
        <Box
            pb={11}
            paddingTop={{ xs: 5, md: 13 }}
            sx={(theme: Theme) => ({ background: `linear-gradient(269.97deg, ${theme.palette.tintPink.main} 0.03%, ${theme.palette.primary.main} 99.97%)` })}
        >
            <InitialPagaHead/>
        </Box>

        <Box mt={-12} mb={6}>
            <Container>
                <HowItWorks/>

                <Form
                    onSubmit={onSubmit}
                    isSending={isFinished}
                    onFormInputTouch={onFormInputTouch}
                />
            </Container>
        </Box>
    </>;
};

