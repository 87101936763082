import { FC } from 'react';
import { Grid } from '@mui/material';
import imgMagazine1Path from 'components/page/leadgeneration/img/png/magazine-1.png';
import imgMagazine2Path from 'components/page/leadgeneration/img/png/magazine-2.png';
import imgMagazine3Path from 'components/page/leadgeneration/img/png/magazine-3.png';
import { Item } from 'components/page/leadgeneration/components/success/magazine/articles/Item';

export const ArticleList: FC = () => {

    const articles = [
        {
            title: 'Pri tvorbe inzerátu treba dodržať týchto 5 zásad',
            link: 'https://www.nehnutelnosti.sk/magazin-o-byvani/1870-ako-by-mal-vyzerat-dobry-inzerat-staci-dodrzat-tychto-5-zasad/',
            imgPath: imgMagazine1Path,
        },
        {
            title: 'Ako pripraviť nehnuteľnosť na predaj',
            link: 'https://www.nehnutelnosti.sk/magazin-o-byvani/943-ako-pripravit-byt-ci-dom-na-predaj/',
            imgPath: imgMagazine2Path,
        },
        {
            title: 'Vyhnite sa pri predaji nehnuteľnosti týmto 6 chybám',
            link: 'https://www.nehnutelnosti.sk/magazin-o-byvani/413-6-najcastejsich-chyb-pri-predaji-nehnutelnosti/',
            imgPath: imgMagazine3Path,
        }
    ];

    return <Grid container justifyContent='center' spacing={2}>
        {articles.map(article =>
            <Grid item xs={12} sm={7} md={4} key={article.title}>
                <Item title={article.title} articleImage={article.imgPath} articleHref={article.link}/>
            </Grid>
        )}
    </Grid>;
};

