import { FC } from 'react';
import { Box } from '@mui/material';
import { Headline } from 'components/page/leadgeneration/components/success/magazine/text/Headline';
import { Description } from 'components/page/leadgeneration/components/success/magazine/text/Description';
import { ActionButton } from 'components/page/leadgeneration/components/success/magazine/text/ActionButton';
import { ArticleList } from 'components/page/leadgeneration/components/success/magazine/articles/ArticleList';

export const Magazine: FC = () => {

    return <>
        <Box maxWidth={580} m='auto' mb={3}>
            <Headline/>
        </Box>

        <Box maxWidth={495} m='auto' mb={4}>
            <Description/>
        </Box>

        <Box mb={4}>
            <ArticleList/>
        </Box>

        <Box textAlign='center'>
            <ActionButton/>
        </Box>
    </>;
};

