'use client';

import { FC } from 'react';
import { Container } from '@mui/material';
import { useRouter, usePathname } from 'next/navigation';
import { Success } from 'components/page/leadgeneration/components/success/Success';
import { Initial } from 'components/page/leadgeneration/components/initial/Initial';
import { useLeadGenActions } from 'components/page/leadgeneration/hooks/useLeadGenActions';
import { usePageViewEvent } from 'modules/gtmEvents/hooks/pageView/usePageViewEvent';

interface MainProps {
    isFinished: boolean;
}

const Main: FC<MainProps> = ({ isFinished }) => {
    const router = useRouter();
    const pathname = usePathname();
    const { onFormInputTouch } = useLeadGenActions();

    usePageViewEvent();

    const handleOnFinish = async () => {
        const urlParams = new URLSearchParams();
        urlParams.append('finished', 'true');
        router.push(`${pathname}?${urlParams.toString()}`);
    };

    return (
        <Container maxWidth={false} disableGutters={true}>
            {isFinished ? (
                <Success />
            ) : (
                <Initial
                    onFinish={handleOnFinish}
                    isFinished={isFinished}
                    onFormInputTouch={onFormInputTouch}
                />
            )}
        </Container>
    );
};

export default Main;