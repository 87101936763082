import { FC } from 'react';
import { Select } from 'components/page/leadgeneration/components/initial/form/fields/select/Select';

interface ISelectType {
    value: string;
    errMsg?: string;
    onChange: (value: string) => void;
}

export const SelectDistrict: FC<ISelectType> = ({ value, errMsg, onChange }) => {

    const options = [
        'Bánovce nad Bebravou',
        'Banská Bystrica',
        'Banská Štiavnica',
        'Bardejov',
        'Bratislava I',
        'Bratislava II',
        'Bratislava III',
        'Bratislava IV',
        'Bratislava V',
        'Brezno',
        'Bytča',
        'Čadca',
        'Detva',
        'Dolný Kubín',
        'Dunajská Streda',
        'Galanta',
        'Gelnica',
        'Hlohovec',
        'Humenné',
        'Ilava',
        'Kežmarok',
        'Komárno',
        'Košice - okolie',
        'Košice I',
        'Košice II',
        'Košice III',
        'Košice IV',
        'Krupina',
        'Kysucké Nové Mesto',
        'Levice',
        'Levoča',
        'Liptovský Mikuláš',
        'Lučenec',
        'Malacky',
        'Martin',
        'Medzilaborce',
        'Michalovce',
        'Myjava',
        'Námestovo',
        'Nitra',
        'Nové Mesto nad Váhom',
        'Nové Zámky',
        'Partizánske',
        'Pezinok',
        'Piešťany',
        'Poltár',
        'Poprad',
        'Považská Bystrica',
        'Prešov',
        'Prievidza',
        'Púchov',
        'Revúca',
        'Rimavská Sobota',
        'Rožňava',
        'Ružomberok',
        'Sabinov',
        'Senec',
        'Senica',
        'Skalica',
        'Snina',
        'Sobrance',
        'Spišská Nová Ves',
        'Stará Ľubovňa',
        'Stropkov',
        'Svidník',
        'Šaľa',
        'Topoľčany',
        'Trebišov',
        'Trenčín',
        'Trnava',
        'Turčianske Teplice',
        'Tvrdošín',
        'Veľký Krtíš',
        'Vranov nad Topľou',
        'Zlaté Moravce',
        'Zvolen',
        'Žarnovica',
        'Žiar nad Hronom',
        'Žilina'
    ];

    return <Select
        value={value}
        options={options}
        errorMsg={errMsg}
        onChange={onChange}
        label='Okres, v ktorom sa nehnuteľnosť nachádza'
    />;
};
