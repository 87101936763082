import { FC } from 'react';
import { Input } from 'modules/theme/components/fields/input/Input';
import { Label } from 'components/page/leadgeneration/components/initial/form/fields/common/Label';

interface ITextInput {
    label: string;
    type: string;
    value: string;
    errorMsg: string;
    onChange: (value: string) => void;
}

export const TextInput: FC<ITextInput> = ({ label, type, errorMsg, value, onChange }) => {
    return <>
        <Label text={label}/>
        <Input type={type} value={value} errorMsg={errorMsg} onChange={onChange}/>
    </>;
};
